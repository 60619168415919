<template>
  <v-container>
    <div class="p-title text-center align-center">
      <h1 class="orange--text">
        MEZCAL POPOL VUH<sup>&reg;</sup>
      </h1>
      <hr class="p-hr">
      <h2
        class="font-weight-light mt-3"
        v-html=content.subheading
      />
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Title',
    computed: {
      content () {
        return this.$store.state.webpagecontent[0][this.$store.state.lang].page
      },
    },
  }
</script>

<style scoped>
  .p-hr {
    border: 2px solid #3F5465;
    border-radius: 5px;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
  }
  .p-title {
    margin-top: 50px;
    margin-bottom: 25px;
  }
</style>
